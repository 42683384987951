$.fn.swipeRight = function(cb){;
    return this.each(function(){
        swipe(this, 'right', cb);
    });
}
$.fn.swipeLeft = function(cb){
    return this.each(function(){
        swipe(this, 'left', cb);
    });
}
$.fn.swipeUp = function(cb){
    return this.each(function(){
        swipe(this, 'up', cb);
    });
}
$.fn.swipeDown = function(cb){
    return this.each(function(){
        swipe(this, 'down', cb);
    });
}

function swipe(el, direction, cb){
    var element = el;
    var swipebool;
        var startP;
        var startX;
        var startY;
        var directionModifier;
        var swipeDirection;
        switch(direction){
            case 'right':
                directionModifier = -1;
                swipeDirection = true;
                break;
            case 'left':
                directionModifier = 1;
                swipeDirection = true;
                break;
            case 'down':
                directionModifier = -1;
                swipeDirection = false;
                break;
            case 'up':
                directionModifier = 1;
                swipeDirection = false;
                break;
            default: directionModifier = 1;;
        }
        element.addEventListener("touchstart", function(e){
            swipebool = true;
            startX = e.changedTouches[0].clientX;
            startY = e.changedTouches[0].clientY;
        }, false); 
        element.addEventListener("touchmove", function(e){
            var distanceX = startX - e.changedTouches[0].clientX;
            var distanceY = startY - e.changedTouches[0].clientY;
            if(swipeDirection){
                if(distanceY < 0 ){distanceY *= -1;}
                if (distanceX <= 150 && swipebool && distanceY < (distanceX * directionModifier)){
                    swipebool = false;
                    return cb.call(this, el);
                }
            } else {
                if(distanceX < 0 ){distanceX *= -1;}
                if (distanceY <= 150 && swipebool && distanceX < (distanceY * directionModifier)){
                    swipebool = false;
                    return cb.call(this, el);
                }
            }
            
        }, false); 
}